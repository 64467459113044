<template>
<div>
  <custom-breadcrumb
        :pageTitle="$t('resources.guides')"
        :breadcrumb="[
          {
            to: 'resources',
            text: this.$t('Educational Resources')
          },
        ]"
      ></custom-breadcrumb>

  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
          <b-form-input
            autofocus
            type="text" 
            v-model="searchText" 
            maxlength="64"
            v-on:keyup.enter="search"
          />
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <b-button block @click="search"  variant="primary">
              <feather-icon              
                icon="SearchIcon"
                size="16"
              />
            </b-button>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <b-button block @click="clear"  variant="secondary">
              <feather-icon              
                icon="XIcon"
                size="16"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-badge 
                  class="mr-1 pointer"
                  v-for="type in content_types" :key="type.id"
                  @click="toggleContentType(type.id)"
                  pill 
                  :variant="type.active? 'primary':'light-primary'">
                    {{ $t('content_types.'+type.name) }}
              </b-badge>
          </b-col>
        </b-row>
      </b-card>
      <b-list-group v-if="filtered_t_guides.length>0">
            <b-list-group-item v-for="guide in filtered_t_guides" :key="guide.id" class="d-flex justify-content-between align-items-center">
              <span class="d-flex" >
                <span style="width:  20px;" class="mr-1">
                  <img :src="require('@/assets/images/content_types/'+(guide.content_type_id)+'.png')" height="20" alt="">
                </span>
                <span class="mr-1">{{ guide.name }}</span>
                <b-badge class="mr-1" pill variant="primary">{{ guide.lang.iso_code.toUpperCase() }}</b-badge>
                <feather-icon
                  v-b-tooltip.hover.top="$t('resources.doc_has_errors')"
                  v-if="guide.status == 'KO'"
                  icon="AlertTriangleIcon"
                  size="16"
                  color="darkorange"
                  class="mr-1"
                />
                <b-spinner small v-if=" guide.status == 'pending' " variant="primary" :label="$t('resources.processing')" />
              </span>
              <span class="pointer" v-if="$can('read', 'Guides')">
                <a v-if="guide.status == 'OK'" target="blank" :download="guide.name" 
                    :href="guide.aws_s3_url_public">
                    <feather-icon
                  v-b-tooltip.hover.top="$t('resources.download')"

                      icon="DownloadIcon"
                      size="16"
                      color="secondary"
                    />
                </a>
              </span>
            </b-list-group-item>
      </b-list-group>
      <p v-else class="text-center" style="color: lightgray; margin-top:40px;">{{$t('resources.no_docs_found')}}</p>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import {
  BFormInput,BButton,
  BListGroup, BListGroupItem, BBadge, VBTooltip,BSpinner,
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody,
} from 'bootstrap-vue'
export default {
   components: {
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,BSpinner,
    BListGroup, BListGroupItem, BBadge,
    CustomBreadcrumb
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {

      filtered_t_guides: [],
      t_guides: [],
      //t_guides_cant: t_guides.length,
      //user: user,
      ajaxLoading: false,
      searchText: '',
      searching: false,
      all_content_types: [],
      content_types:[],
    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){
      this.getData()
    }
  },
  methods:{
    toggleContentType(type_id){
      let ct = []
      this.all_content_types.map(x => {
        if(x.id == type_id){
          x['active'] = !x['active']
        }
        ct.push(x)
      })
      this.content_types = ct
      
      this.search()
    },
    search(){
      if(this.searchText == ''){
        this.filtered_t_guides = this.t_guides;
      } else {
        let guides = []
  
        this.t_guides.forEach(element => {
          if(element.name.toLowerCase().includes(this.searchText.toLowerCase())){
            guides.push(element)
          }
        });
        this.filtered_t_guides = guides;
      }
      this.filterContentByType()
    },
    filterContentByType(){     
      //todo active o todo inactive
      let is_all_active = true
      let is_all_inactive = true
      let active_filters = []
      this.content_types.map(x => {
        if(!x.active){
          is_all_active = false
        }
        if(x.active){
          is_all_inactive = false
          active_filters.push(x.id)
        }
      })
     //console.log(active_filters)
      if(is_all_active || is_all_inactive){
        //pass -> do nothing
      } else { 
        // por cada contendo, si el type = active 
        let fg = [] 
        this.filtered_t_guides.map(x => {
          if(active_filters.includes(x.content_type_id) ){
            fg.push(x)
          }
        })
        this.filtered_t_guides = fg
      } 
    },
    clear(){
      this.searchText  = '';
      this.filtered_t_guides = this.t_guides;
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getData(){
      await this.$http.post('resources/teaching_guides', {}).then( response => {
        if(response.data.status == 200){
          //this.videos = response.data.videos
          console.log(response.data)

          this.filtered_t_guides = response.data.t_guides
          this.t_guides = response.data.t_guides
          
          let ct = []
          response.data.all_content_types.map(x => {
            x['active'] = false
            ct.push(x)
          })
          this.content_types = ct
          this.all_content_types = ct
          
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        }
      })
    }
  }
}
</script>

<style>
</style>